.wrapper > .header{
	.pr;
	.width;
	top: 0px;
	.floor(3);
	left: 0px;
	background-color: @themeColor;
	>.l_con{
		.pr;
		height: 113px;
	}
	.left{
		.fl;
		width: 495px;
		.logo{
			width: 457px;
			height: 60px;
			background: url('../images/change_doc/hb/hb_soft_logo.png') no-repeat;
			background: url('../images/change_doc/hb/hb_soft_logo.svg') center/contain no-repeat;
			margin-top: 28px;
		}
	}
	.right{
		.pa;
		top: 0px;
		right: 0px;
		bottom: 0px;
		height: 100%;
		width: 600px;
	}
	.relt_info,.user{
		.fr;
		height: 33px;
		margin-top: 40px;
		font-size: 0px!important;
		& , >a,>span{
			height: 33px;
			.inline-block;
			.transition(.22s);
			.f(@fs2,#ccc,33px);
			&:hover{
				color: #ccc;
			}
		}
		>.login{
			color: #eee;
			.transition(.06s);
			margin-right: 8px;
			padding-left: 26px;
			background-repeat: no-repeat;
			background-position: left center;
			background-image: url('../images/change_doc/hb/hb_soft_user.png');
			&:hover{
				color: #fff;
				background-image: url('../images/change_doc/hb/hb_soft_user_hover.png');
			}
		}
		>.register{
			color: #eee;
			.transition(.06s);
			margin-left: 8px;
			&:hover{
				color: #fff;
			}
		}
		>.userAccount{
			color: #eee;
			margin-right: 8px;
			.transition(.06s);
			padding-left: 26px;
			background-repeat: no-repeat;
			background-position: left center;
			background-image: url('../images/change_doc/hb/hb_soft_user.png');
			&:hover{
				color: #fff;
				background-image: url('../images/change_doc/hb/hb_soft_user_hover.png');
			}
		}
		>.quiz{
			color: #eee;
			.transition(.06s);
			margin-left: 8px;
			&:hover{
				color: #fff;
			}
		}
	}
	.search{
		.pr;
		.fr;
		.clear;
		height: 32px;
		display: block;
		font-size: 0px;
		margin-top: 41px;
		padding-right: 22px;
		input{
			.fr;
			color: #333;
			width: 256px;
			height: 30px;
			font-size: 14px;
			.transition(.22s);
			line-height: 30px;
			padding-left: 20px;
			padding-right: 44px;
			.border-radius(32px);
			border: 1px solid #bbb;
			background-color: rgba(255,255,255,.2);
			&{
				.placeholder({
					color: #eee;
					font-size: 14px;
				});
			}
			&.placeholder{
				color: #eee;
				font-size: 14px;
			}
			&:focus{
				// border: 1px solid #999;
				.box-shadow(rgba(128,128,128,.5),0px,0px,6px);
			}
		}
		.btn_search{
			.pa;
			top: 8px;
			right: 43px;
			width: 16px;
			height: 16px;
			background-image: url('../images/change_doc/hb/search.png');
		}
	}
	// &.qna{
	// 	.pa;
	// 	left: 0px;
	// 	background-color: @themeColor;
	// 	background-color: fade(@themeColor,90);
	// 	.search{
	// 		input{
	// 			color: #fff;
	// 			border: 1px solid #ccc;
	// 			background-color: #b6bede;
	// 			background-color: rgba(255,255,255,.3);
	// 			&{
	// 				.placeholder({
	// 					color: rgba(245,249,255,.6);
	// 				});
	// 			}
	// 			&.placeholder{
	// 				color: rgba(245,249,255,.6);
	// 			}
	// 		}
	// 	}
	// }
}

@keyframes move {
	from {
		.translate(0px,-45px);
	}
	to {
		.translate(0px,0px);
	}
}

@-moz-keyframes move {
	from {
		.translate(0px,-45px);
	}
	to {
		.translate(0px,0px);
	}
}

@-webkit-keyframes move {
	from {
		.translate(0px,-45px);
	}
	to {
		.translate(0px,0px);
	}
}

@-o-keyframes move {
	from {
		.translate(0px,-45px);
	}
	to {
		.translate(0px,0px);
	}
}

.footer{
	background-color: #d1d6e3;
	.l_con{
		.clear;
		padding-top: 27px;
		padding-bottom: 15px;
		.logo{
			.fl;
			width: 256px;
			height: 60px;
			margin-top: 8px;
			background: url('../images/change_doc/hb/logo_footer.png') no-repeat;
			/*background: url('../images/change_doc/hb/logo_footer.svg') center/contain no-repeat;*/
			background-size: auto 100%;
		}
		.center{
			.fl;
			width: 700px;
			margin-top: 15px;
			margin-left: 22px;
			padding-left: 22px;
			.links{
				.clear;
				margin-top: -10px;
				.item{
					.fl;
					margin-right: 22px;
					padding-top: 5px;
					padding-bottom: 5px;
					.f(14px,@themeColor,22px);
					&:hover{
						text-decoration: underline;
					}
				}
			}
			.info{
				margin-bottom: -10px;
				padding-top: 5px;
				.f(12px,#8892b6,22px);
				padding-bottom: 5px;
			}
		}
		.codes{
			.fr;
			.code_1,.code_2{
				.fl;
				width: 60px;
				.img{
					width: 60px;
					height: 60px;
					background-image: url('../images/change_doc/hb/code_wb.png');
					background-repeat: no-repeat;
					background-color: #efefef;
				}
				.txt{
					.pr;
					.tc;
					left: 50%;
					width: 150px;
					margin-top: 8px;
					margin-left: -75px;
					.f(14px,@themeColor,100%);
				}
			}
			.code_2{
				margin-left: 35px;
				.img{
					background-image: url('../images/change_doc/hb/code_gf.png');
				}
			}
		}
	}
	.support{
		.tc;
		height: 45px;
		.f(12px,#bbb,45px);
		background-color: @themeColor;
	}
}